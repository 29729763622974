<messages>["./SetNew"]</messages>

<template>
  <v-row justify="space-around">
    <v-col cols="12" sm="6">
      <v-card>
        <form
          novalidate
          @submit.prevent="onSubmit">
          <v-card-title primary-title>
            <h3
              class="text-h5 mb-0"
              v-text="$t ('passwordReset.setNew.title')">
              Set new user
              password
            </h3>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim="username"
              name="username"
              :label="$t('username.label')"
              :error-messages="requiredErrors(
                'username',
                'username.required')"
              class="required"
              @input="$v.username.$touch()"
              @blur="$v.username.$touch()"/>
            <v-text-field
              v-model="tmpPassword"
              name="tmpPassword"
              :label="$t('tmpPassword.label')"
              :error-messages="requiredErrors(
                'tmpPassword',
                'tmpPassword.required')"
              :append-icon="isTmpPasswordHidden ? 'visibility' : 'visibility_off'"
              :type="isTmpPasswordHidden ? 'password' : 'text'"
              class="required"
              @click:append="isTmpPasswordHidden = !isTmpPasswordHidden"
              @input="$v.tmpPassword.$touch()"
              @blur="$v.tmpPassword.$touch()"/>
            <v-text-field
              v-model="password"
              name="password"
              :label="$t('password.label')"
              :error-messages="requiredErrors(
                'password',
                'password.required')"
              :append-icon="isPasswordHidden ? 'visibility' : 'visibility_off'"
              :type="isPasswordHidden ? 'password' : 'text'"
              class="required"
              @click:append="isPasswordHidden = !isPasswordHidden"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"/>
            <div class="text-center">
              <v-btn type="submit">
                <div
                  v-if="!isWorking"
                  v-t="'action.label'"/>
                <div v-else>
                  <v-progress-circular
                    indeterminate
                    color="primary"/>
                </div>
              </v-btn>
            </div>
          </v-card-text>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'SetNew',

    mixins: [validationMixins],

    data () {
      return {
        username: this.$route.query.username || '',
        tmpPassword: this.$route.query.tmpPassword || '',
        isTmpPasswordHidden: true,
        password: '',
        isPasswordHidden: true,
        isWorking: false
      }
    },

    validations: {
      username: {required},
      tmpPassword: {required},
      password: {required}
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ('request', [
        'fetchData'
      ]),

      async onSubmit () {
        this.isWorking = true

        if (!this.$v.$invalid) {
          await this.setNewPassword ()
        } else {
          this.$v.$touch ()
        }

        this.isWorking = false
      },

      setNewPassword () {
        return this.fetchData ({
          op: 'setNewPassword',
          params: {
            username: this.username,
            tmpPassword: this.tmpPassword,
            password: this.password
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('successMessage', {name: this.username}))

            this.$router.push ({name: 'login'})
          }
        })
      }
    }
  }
</script>
